import React from "react"
import styled from "styled-components"
import LogoBlack from "@components/Logos/LogoBlack"
import Link from "@components/Link"

const LogoContainer = styled(Link)`
  display: block;
  position: relative;
  width: 150px;
  padding: 10px;
`

const BrandMark = () => (
  <LogoContainer to={"/"}>
    <LogoBlack />
  </LogoContainer>
)

export default BrandMark
