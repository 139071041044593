import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Color from "@styles/colors"

export const StyledLink = styled.a`
  color: ${Color.black};
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: ${Color.primary.normal};
  }
`

const StyledGatsbyLink = styled(GatsbyLink)`
  color: ${Color.black};
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: ${Color.primary.normal};
  }
`

const Link = ({ children, to, external, ...props }) => {
  if (external === true) {
    return (
      <StyledLink
        href={to}
        className={props.className}
        target={"_blank"}
        rel={"noopener noreferrer"}
        {...props}
      >
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledGatsbyLink to={to} {...props}>
      {children}
    </StyledGatsbyLink>
  )
}

Link.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default Link
