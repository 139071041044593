export default {
  white: "#ffffff",
  black: "#000000",
  darkGray: {
    normal: "#191a1b",
  },
  gray: {
    lightest: "#fbfafa",
    normal: "#e8e8e8",
    dark: "#747474",
    darkest: "#2e2e2e",
  },
  primary: {
    lightest: "#fef8db",
    light: "#fceea6",
    normal: "#f9dd4c",
    dark: "#e0c744",
  },
  secondary: {
    lightest: "#d6e1fe",
    light: "#99b5fc",
    normal: "#336AF8",
    dark: "#1f4095",
    darkest: "#0f204a",
  },
  tertiary: {
    normal: "#8ef8f0",
  },
  success: {
    light: "#d4edda",
    normal: "#8fd19e",
    dark: "#155724",
  },
}
