import React from "react"
import Hamburger from "react-hamburger-menu"
import PropTypes from "prop-types"
import styled from "styled-components"
import mediaQueries from "@styles/mediaQueries"

const StyledHamburgerMenu = styled.div`
  //margin-right: 20px;
  display: none;
  @media (max-width: ${mediaQueries.lg}) {
    display: block;
    position: absolute;
    top: 27px;
    right: 20px;
    cursor: pointer;
  }
`

const HamburgerMenu = ({ isOpen, onClick }) => {
  return (
    <StyledHamburgerMenu>
      <Hamburger
        isOpen={isOpen}
        menuClicked={onClick}
        width={18}
        height={15}
        strokeWidth={1}
        rotate={0}
        color="black"
        borderRadius={0}
        animationDuration={0.5}
      />
    </StyledHamburgerMenu>
  )
}
HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
export default HamburgerMenu
