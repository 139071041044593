import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import mediaQueries from "@styles/mediaQueries"
import NavItem from "./NavItem"

const StyledNavbar = styled.nav`
  @media (max-width: ${mediaQueries.lg}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: 100%;
  }
`

const NavBarContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: ${mediaQueries.lg}) {
    flex-direction: column;
  }
`

const Navbar = ({ isOpen }) => {
  const {
    site: {
      siteMetadata: { urls },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          urls {
            login
            documentation
          }
        }
      }
    }
  `)

  return (
    <StyledNavbar isOpen={isOpen}>
      <NavBarContainer>
        <NavItem to={"/docs"} label={"Learn More"} />
        <NavItem
          to={urls.login}
          external
          type={"primary"}
          label={"Get Started"}
        />
      </NavBarContainer>
    </StyledNavbar>
  )
}

Navbar.propTypes = {
  isOpen: PropTypes.bool,
}
export default Navbar
