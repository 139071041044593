import React from "react"
import styled from "styled-components"
import Link from "@components/Link"
import Container from "./Container"
import { Flex, Box } from "reflexbox"

const Footer = styled.div`
  padding: 20px 0;
  border-top: 2px dotted #ccc;
`

export default () => {
  return (
    <Footer>
      <Container>
        <Flex>
          <Box>
            &copy; {new Date().getFullYear()} - ChangeHub is being created by{" "}
            <Link
              to="https://madewithlove.be"
              title="Visit the madewithlove website"
              external
            >
              madewithlove
            </Link>
          </Box>
          <Box ml={"auto"}>
            <Link to={"/legal"} title={"Legal"}>
              Legal
            </Link>
          </Box>
        </Flex>
      </Container>
    </Footer>
  )
}
