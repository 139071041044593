import PropTypes from "prop-types"
import styled from "styled-components"
import Color from "@styles/colors"
import { fontSizes } from "@styles/typography"
import Link from "./Link"

const Button = styled(Link)`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 10px;
  color: ${Color.black};
  text-decoration: none;
  padding: 0.6rem 1rem;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  
  &:hover {
    background-color: ${Color.gray.darkest};
    text-decoration: none;
  }
  
  ${(props) =>
    props.size === "lg" &&
    `
    font-size: ${fontSizes.lg};
    padding: 1.2rem 2rem;
    font-weight: bold;
  `}
  
  ${(props) =>
    props.type === "primary" &&
    `
    background-color: ${Color.primary.normal};
   
    &:hover {
      background-color: ${Color.primary.dark};
      color: ${Color.gray.darkest};
    }
  `}
  
  ${(props) =>
    props.outline &&
    props.type === "primary" &&
    `
     background-color: transparent;
     color: ${Color.primary.normal};
    
     &:hover {
       color: ${Color.white};
      } 
  `}
  
  ${(props) =>
    props.type === "secondary" &&
    `
    border: 1px solid ${Color.secondary.normal};
    background-color: ${Color.secondary.normal};
    color: ${Color.white};
   
    &:hover {
      background-color: ${Color.secondary.dark};
    }
  `}
 
  ${(props) =>
    props.outline &&
    props.type === "secondary" &&
    `
     background-color: transparent;
     color: ${Color.secondary.normal};
    
     &:hover {
       color: ${Color.white};
      } 
  `}
`

Button.propTypes = {
  size: PropTypes.oneOf(["lg"]),
  type: PropTypes.oneOf(["primary", "secondary"]),
  outline: PropTypes.bool,
}

export default Button
