import React, { useState } from "react"
import styled from "styled-components"
import Color from "../../styles/colors"
import Navbar from "./Navbar"
import BrandMark from "./BrandMark"
import HamburgerMenu from "./HamburgerMenu"
import mediaQueries from "@styles/mediaQueries"

const Header = styled.header`
  position: relative;
  z-index: 2;
  background-color: ${Color.white};
  border-bottom: 1px solid ${Color.gray.normal};
`

const HeaderContainer = styled.div`
  padding: 10px 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${mediaQueries.lg}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Header>
      <HeaderContainer>
        <BrandMark to={"/"} />
        <Navbar isOpen={isOpen} />
        <HamburgerMenu isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </HeaderContainer>
    </Header>
  )
}
