import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import mediaQueries from "@styles/mediaQueries"
import Button from "@components/Button"

const NavItemContainer = styled.li`
  list-style-type: none;

  &:last-child {
    margin-left: 0.5rem;
  }

  @media (max-width: ${mediaQueries.lg}) {
    display: block;

    & + li {
      margin: 1rem 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }
`

const NavItem = ({ as, label, ...props }) => {
  const Component = as

  return (
    <NavItemContainer>
      <Component {...props} activeClassName={"active"} partiallyActive>
        {label}
      </Component>
    </NavItemContainer>
  )
}

NavItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  isButton: PropTypes.bool,
  external: PropTypes.bool,
}

NavItem.defaultProps = {
  as: Button,
}

export default NavItem
